import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
// import {
//   // AltPanels,
// } from 'layouts';
import {
  Banner,
  // Breadcrumbs,
} from 'components';
import { BASE_URL } from 'config';

const showdown = require('showdown');

const converter = new showdown.Converter();

// const BREADCRUMBS = [
//   {
//     name: 'About Us',
//     absolute_slug: PAGES.ABOUT_BRAND.PATH,
//   },
//   {
//     name: PAGES.ABOUT_SCHOLARSHIPS.TITLE,
//     absolute_slug: PAGES.ABOUT_SCHOLARSHIPS.PATH,
//   },
// ];

const AboutScholarshipPage = ({ data }) => (
  <Layout page="about/maori-tour-manager-scholarships">

    <Helmet
      title="Māori-tour-manager-scholarships"
      meta={[
        {
          name: 'description', content: `Apply for the Haka Tours Māori Tour Manager Scholarship and the selected candidate will receive funding to the value of $5000
          for training and anything you need to prepare for the exciting role of a New Zealand Tour Manager.`,
        },
        { name: 'og:title', content: 'Māori-tour-manager-scholarships' },
        {
          name: 'og:description', content: `Apply for the Haka Tours Māori Tour Manager Scholarship and the selected candidate will receive funding to the value of $5000
          for training and anything you need to prepare for the exciting role of a New Zealand Tour Manager.`,
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/maori-tour-manager-scholarships/` }]}
    />

    <Banner backgroundImage="https://hakatours-staging.imgix.net/000/Maori_Scholarship.jpg?fit=crop&auto=format">
      <h2 className="c-heading c-heading--h0">Māori tour manager scholarships</h2>
    </Banner>
    <section className="l-section">
      <div className="l-container l-container--small">
        <div
          dangerouslySetInnerHTML={{
            __html: converter.makeHtml(data.allStaticPages.edges[0].node.content_markdown),
          }}
        />
      </div>
    </section>
  </Layout>
);

AboutScholarshipPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutScholarshipPage;

export const query = graphql`
  query {
    allStaticPages(
      filter: {
        slug: { eq: "maori-tour-manager-scholarships" },
      }
    ) {
      edges {
        node {
          id
          api_id
          slug
          content_markdown
        }
      }
    }
  }
`;
